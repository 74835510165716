import React, { useEffect, useState } from "react";

const Download = () => {
  const [dModal, setDModal] = useState(false);

  useEffect(() => {
    // setDModal(true);
    // return;
    if (navigator.userAgent.includes("Android")) {
      window.open("https://play.google.com/store/apps/details?id=com.partsxchangemobile", "_self");
    } else if (navigator.userAgent.includes("iPhone")) {
      window.open("https://apps.apple.com/gb/app/partsxchange/id1671168566", "_self");
    } else {
      window.open("https://partsxchange.co.uk/", "_self");
    }
  }, []);

  const modal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex  min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#ffffff] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Coming Soon...`}</h1>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  <div className="py-2 rounded-lg">
                    {`The application is under construction.`}
                  </div>
                  <div className="flex justify-end mt-2">
                    <div className="flex justify-end">
                      <button
                        onClick={() => {
                          setDModal(false);
                          window.open("https://partsxchange.co.uk/", "_self");
                        }}
                        className="bg-theme text-lg pt-4 pb-3 mb-4 px-12 text-white shadow-md font-medium shadow-theme/70 !leading-none rounded-full "
                      >
                        <span style={{ marginBottom: "2px" }}>Ok</span>
                      </button>
                      {/* <button
                        className={`bg-themecolor ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-3`}
                        onClick={() => {
                          setDModal(false);
                          window.open("https://partsxchange.co.uk/", "_self");
                        }}
                      >
                        OK
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return <div>{dModal && modal}</div>;
};

export default Download;
