import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../assets";
import CustomSpinner from "../components/customSpinner";
import authStore from "../stores/auth";

const Partners = () => {
  const { loading, getPartnersAPI, getPartnersData } = authStore((state) => ({
    getPartnersAPI: state?.getPartnersAPI,
    loading: state.loading,
    getPartnersData: state?.getPartnersData?.data?.results,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getPartnersAPI(); // eslint-disable-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <main>
          {loading && <CustomSpinner />}
          {/* <!-- HEADER-START --> */}
          <section className="bg-header w-full h-full bg-no-repeat bg-contain relative">
            <header className="w-full   px-4">
              <div className="container mx-auto 2xl:max-w-6xl xl:px-4 pt-7 pb-4">
                <div className="flex justify-between">
                  <div className="xl:w-[320px] sm:w-[250px] w-[200px] my-auto">
                    <Link to="/">
                      <img src={Logo} className="w-full" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            </header>
            <div className="container mx-auto 2xl:pt-14 xl:pt-10   px-4 2xl:max-w-6xl xl:pb-10 md:py-8  xs:py-6 py-4">
            <span className="font-semibold text-white pt-14 xl:px-3 2xl:px-0  py-10 md:text-3xl xs:text-2xl text-xl">
                Partners
              </span>
            </div>
          </section>
          {/* <!-- HEADER-END --> */}

          <div className="py-20 container mx-auto 2xl:max-w-6xl ">
            <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 gap-10 px-4">
              {getPartnersData?.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col justify-center items-center"
                >
                  <div className="xs:w-full w-48 mx-auto md:h-44 h-40 ">
                    <img
                      src={item?.partner_image}
                      alt="partners_image"
                      className="h-full w-full mx-auto object-contain"
                    />
                  </div>
                  <div className="mt-2">
                    <p className="font-medium">{item?.partner_name || ""}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <!-- FOOTER-START --> */}
          <section className="bg-footer w-full bg-no-repeat bg-contain relative">
            <div className="flex items-center justify-center py-12">
              <div className="xl:w-[320px] w-[250px]">
                <img src={Logo} className="w-full" alt="" />
              </div>
            </div>
            <div className="flex flex-wrap md:flex-nowrap items-center justify-center px-4 pb-6">
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/terms-condition">Terms & Conditions</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/help_and_support">Help And Support</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/parts_grading">Parts Grading</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/partners">Partners</Link>
            </p>
          </div>
          </section>
          {/* <!-- FOOTER-END --> */}
        </main>
      </div>
    </>
  );
};

export default Partners;
