import React from "react";
import { Link } from "react-router-dom";

const LoginButton = () => {
  return (
    <Link to="https://app.partsxchange.co.uk" target="_blank">
      <button className="login-button">Login</button>
    </Link>
  );
};

export default LoginButton;
