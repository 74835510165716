import { Route, Routes } from "react-router-dom";
import Download from "./Download";
import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsCondition from "./TermsCondition";
import PartsGrading from "./PartsGrading";
import HelpSupport from "./HelpsSupport";
import Partners from "./Partners";


const AuthRoute = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-condition" element={<TermsCondition />} />
      <Route exact path="/download" element={<Download />} />
      <Route exact path="/help_and_support" element={<HelpSupport />} />
      <Route exact path="/parts_grading" element={<PartsGrading />} />
      <Route exact path="/partners" element={<Partners />} />
    </Routes>
  );
};

export default AuthRoute;
