import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import LazyloadLoader from "../components/common/LazyLoader";
import AuthRoute from "../pages";

const AppRoutes = () => {
  return (
    <Suspense fallback={<LazyloadLoader />}>
      <Routes>
        <Route path="*">
          <Route path="*" element={<AuthRoute />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default AppRoutes;
