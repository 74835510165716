import { toast } from "react-toastify";
import create from "zustand";
import { persist } from "zustand/middleware";
import { axiosApi } from "../helpers/axios";
import { firstCap } from "../utils/constants";

const authStore = create(
  persist(
    (set, get) => ({
      getDetailsData: {},
      contactUsData: {},
      loading: false,
      error: null,

      getDetailsAPI: async (payload) => {
        try {
          set({ loading: true, getDetailsData: {}, error: null });
          const response = await axiosApi.get(
            `/cms/get-details/${payload}`,
            null
          );
          set({
            loading: false,
            getDetailsData: response.data,
            error: null,
          });
          // toast.success(firstCap(response?.data?.msg));
          return response;
        } catch (error) {
          set({
            loading: false,
            getDetailsData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },

      getPartnersAPI: async () => {
        try {
          set({ loading: true, getPartnersData: {}, error: null });
          const response = await axiosApi.get(`/partner/list`, null);
          set({
            loading: false,
            getPartnersData: response.data,
            error: null,
          });
          return response;
        } catch (error) {
          set({
            loading: false,
            getPartnersData: {},
            error: error.response.data.message,
          });
          toast.error(firstCap(error.response.data.message));
          return error.response.data.message;
        }
      },

      contactUsAPI: async (payload, navigate) => {
        try {
          set({ loading: true, contactUsData: {}, error: null });
          const response = await axiosApi.post(`/contact-us/create`, payload);
          set({
            loading: false,
            contactUsData: response?.data,
            error: null,
          });
          toast.success(firstCap(response?.data?.message));
          return response;
        } catch (error) {
          set({
            loading: false,
            contactUsData: {},
            error: error?.response?.data?.message,
          });
          toast.error(firstCap(error?.response?.data?.message));
          return error?.response?.data?.message;
        }
      },
    }),
    { name: "auth-storage", getStorage: () => sessionStorage }
  )
);

export default authStore;
