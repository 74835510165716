import { BrowserRouter } from "react-router-dom";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
      <ToastContainer
        position="bottom-right"
        toastClassName={{ fontFamily: "Whyte" }}
      />
    </BrowserRouter>
  );
}

export default App;
