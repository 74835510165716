/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginButton from "../components/ LoginButton";
import {
  APPStorePNG,
  BannerIphoneMin,
  Cash,
  Facebook,
  Feature,
  Feature2,
  Feature3,
  Grow,
  Increase,
  Instagram,
  Iphone14pro,
  Iphones,
  Linkedin,
  Logo,
  Per,
  PlayStorePNG,
  QR_CODE,
  Right,
  Shadow1,
  Shadow2,
  Shadow3,
  Shadow5,
  Twitter,
  videoThumbNail,
  VIDEO_PARTS,
  videoThumbNail1,
  VIDEO_PARTS1,
} from "../assets";

import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import authStore from "../stores/auth";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [link, setLink] = useState("");

  const { contactUsAPI } = authStore((state) => ({
    contactUsAPI: state.contactUsAPI,
    loading: state.loading,
  }));

  useEffect(() => {
    if (navigator.userAgent.includes("Android")) {
      setLink(
        "https://play.google.com/store/apps/details?id=com.partsxchangemobile",
        "_self"
      );
    } else if (navigator.userAgent.includes("iPhone")) {
      setLink(
        "https://apps.apple.com/gb/app/partsxchange/id1671168566",
        "_self"
      );
    } else {
      setLink("https://play.google.com/store", "_self");
    }
  }, []);

  return (
    <main>
      {/* <!-- BANNER-START --> */}
      <section className="bg-banner w-full 2xl:h-screen min-h-[735px] h-full bg-no-repeat bg-contain relative">
        <div className="bg-main w-full h-20">
          <header className="w-full lg:px-[110px] xl:px-4 px-4">
            <div className="container mx-auto 2xl:max-w-6xl xl:px-4 pt-7 pb-4">
              <div className="flex justify-between">
                <div className="xl:w-[320px] sm:w-[250px] w-[200px] my-auto">
                  <img src={Logo} className="w-full" alt="" />
                </div>
                <LoginButton /> 
              </div>
            </div>
          </header>
        </div>
        <div className="container mx-auto px-4 2xl:max-w-6xl">
          <div className="sm:grid grid-cols-2 sm:items-start">
            <div className="2xl:py-24 xl:py-16 sm:py-12 py-7 2xl:max-w-xl max-w-lg 2xl:mx-0 mx-auto ">
              <h1 className="text-white font-semibold 2xl:text-[60px] xl:text-5xl text-[44px] 2xl:!leading-[65px] xl:!leading-[55px] !leading-[50px] lg:pl-[110px] xl:pl-0">
                <span className="sm:text-[#ECEAC0] text-[#37a2ac] md:text-[44px] sm:text-[40px] xs:text-4xl text-3xl md:mb-6 sm:mb-4 mb-1 block">
                  Buy & Sell
                </span>
                <div className="flex sm:block gap-1">
                  <span className="sm:text-white text-black md:text-[44px] sm:text-[40px] xs:text-2xl text-xl md:mb-5 sm:mb-3.5 block">
                    Genuine New
                  </span>
                  <span className="sm:text-white text-black md:text-[44px] sm:text-[40px] xs:text-2xl text-xl sm:leading-tight  sm:mb-6 block">
                    {`And Used Parts`}
                  </span>
                </div>
              </h1>
              <div className="flex sm:block gap-1 md:ml-1 sm:ml-7 ">
                <p className="2xl:text-2xl xl:text-xl sm:text-lg text-sm sm:text-white/70 text-black/70 pb-1 lg:pl-[110px] xl:pl-0 block">
                  Fastest Growing Bodyshop{" "}
                </p>
                <span className="2xl:text-2xl xl:text-xl sm:text-lg text-sm sm:text-white/70 text-black/70 sm:py-0 pb-2 lg:pl-[110px] xl:pl-0 block">
                  {" "}
                  Marketplace
                </span>
              </div>

              <div className="lg:ml-[110px] xl:ml-0 ">
                <div className="xl:max-w-[350px] lg:max-w-[500px]">
                  <div className="rounded-lg text-center plan cursor-pointer p-5 mt-10 sm:bg-transparent bg-black/20">
                    <div className="flex justify-between">
                      <img src={QR_CODE} alt="" className="h-32" />
                      <div className="flex flex-col justify-between ml-5">
                        <span className="2xl:text-2xl xl:text-xl text-lg sm:text-white text-black sm:py-0 pb-2 block">
                          Get the App
                        </span>

                        <a
                          href={
                            "https://apps.apple.com/gb/app/partsxchange/id1671168566"
                          }
                          alt=""
                          target="_blank"
                        >
                          <img src={APPStorePNG} alt="" className="h-10 mt-2" />
                        </a>
                        <a
                          href={
                            "https://play.google.com/store/apps/details?id=com.partsxchangemobile"
                          }
                          alt=""
                          target="_blank"
                        >
                          <img
                            src={PlayStorePNG}
                            alt=""
                            className="h-10 mt-2"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full xl:py-12 py-8 xl:px-28 md:px-20 px-14 2xl:py-0 flex 2xl:justify-end justify-center">
              <div className="2xl:translate-y-28 2xl:w-[385px] xl:w-auto lg:w-[295px] w-auto 2xl:min-w-[385px]">
                <img
                  src={BannerIphoneMin}
                  className="w-full mx-auto object-contain"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 w-full -z-10">
          <img src={Shadow3} className="w-full" alt="" />
        </div>
      </section>
      {/* <!-- BANNER-END --> */}
      {/* <!-- TOTAL-START --> */}
      <section className="md:py-24 py-12 relative">
        <div className="container mx-auto 2xl:max-w-6xl">
          <div className="grid sm:grid-cols-2 gap-5 items-center px-4">
            <div className="grid grid-cols-2 flex-start gap-4 lg:p-10 md:p-4 max-w-lg">
              <div className="space-y-4">
                <div className="bg-white rounded-2xl shadow-2xl shadow-cardshadow py-4 lg:px-5 px-3">
                  <div className="pb-1.5">
                    <img src={Cash} alt="" />
                  </div>
                  <div className="py-5 2xl:mt-8 lg:text-lg font-semibold">
                    <p className="text-themetext  !leading-none ">
                      Improve Cash
                    </p>
                    <p className="text-themetext !leading-none pt-1.5">Flow</p>
                  </div>
                </div>
                <div className="bg-white rounded-2xl lg:text-lg shadow-2xl shadow-cardshadow py-4 lg:px-5 px-3">
                  <div className="pb-1.5">
                    <img src={Grow} alt="" />
                  </div>
                  <div className="py-5 2xl:mt-8 font-semibold">
                    <p className="text-themetext  !leading-none">Increase</p>
                    <p className="text-themetext  !leading-none pt-1.5">
                      Productivity
                    </p>
                  </div>
                </div>
              </div>
              <div className="space-y-4 mt-10">
                <div className="bg-white rounded-2xl lg:text-lg shadow-2xl shadow-cardshadow py-4 lg:px-5 px-3">
                  <div className="pb-1.5">
                    <img src={Increase} alt="" />
                  </div>
                  <div className="py-5 2xl:mt-8 font-semibold">
                    <p className="text-themetext  !leading-none">Increase</p>
                    <p className="text-themetext  !leading-none pt-1.5">
                      Profitability
                    </p>
                  </div>
                </div>
                <div className="bg-white rounded-2xl lg:text-lg shadow-2xl shadow-cardshadow py-4 lg:px-5 px-3">
                  <div className="pb-1.5">
                    <img src={Per} alt="" />
                  </div>
                  <div className="py-5 2xl:mt-6 font-semibold">
                    <p className="text-themetext !leading-none">
                      Supply and buy
                    </p>
                    <p className="text-themetext  !leading-none pt-1.5">
                      GENUINE
                    </p>
                    <p className="text-themetext !leading-none pt-1.5">
                      Parts at a
                    </p>
                    <p className="text-themetext !leading-none pt-1.5">
                      Discounted Cost
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5 sm:pt-0 sm:max-w-sm max-w-lg flex flex-col items-center justify-center">
              <h3 className="font-semibold text-themetext md:text-3xl text-2xl">
                Supply GENUINE parts at a discounted cost
              </h3>
            </div>
          </div>
        </div>
        <div className="absolute top-0 left-0 -z-10 w-2/5">
          <img src={Shadow2} className="w-full" alt="" />
        </div>
      </section>
      {/* <!-- TOTAL-END --> */}
      {/* <!-- DOWNLOAD-START --> */}
      <section className="sm:py-24 py-14 relative">
        <div className="container mx-auto px-4 2xl:max-w-6xl">
          <div className="sm:grid grid-cols-2  gap-7 items-center">
            <div className=" ">
              <div className="sm:block flex flex-col justify-center items-center">
                <img src={Right} alt="" />
              </div>
              <div className="max-w-lg pt-8">
                <h3 className="font-semibold text-themetext lg:text-2xl text-lg">
                A part can be listed using the quick and simple app. Each item takes less than 90 seconds to list, including an image. Alternatively, you can simply upload a batch of parts using a CSV file.
                </h3>
                <h3 className="font-semibold text-themetext lg:text-2xl text-lg lg:py-2">
                  Parts are listed for sale at a pre determined cost along
                  with cost of the postage.
                </h3>
                <p className="text-themetext lg:text-lg lg:py-4 py-3">
                  When a part is sold you will receive a message from
                  PartsXchange with the name and address of the person that has
                  bought the part.
                </p>
                <p className="text-themetext lg:text-lg py-4">
                  PartsXchange will automatically notify the collection agent to
                  collect the item for delivery.
                </p>
                <div className="sm:block flex flex-col justify-center items-center">
                  <a href={link} alt="" target="_blank">
                    <button className="bg-theme text-lg pt-4 pb-3 px-5 text-white shadow-md font-medium shadow-theme/70 !leading-none rounded-full ">
                      <span style={{ marginBottom: "2px" }}>Download</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="w-full sm:mt-0 mt-5">
              <div className="w-full flex justify-center">
                <img src={Iphone14pro} className="w-80" alt="" />
              </div>
            </div>
          </div>
          <div className="absolute -top-6 right-48 w-1/2 -z-10">
            <img src={Shadow1} className="w-full" alt="" />
          </div>
        </div>
      </section>
      {/* <!-- DOWNLOAD-END --> */}
          {/* <!-- VIDEO-START --> */}
      <section className=" relative">
        <div className="container px-4 mx-auto max-w-6xl">
          <div className="w-full rounded sm:rounded-[3rem] overflow-hidden sm:h-[430px] md:h-[500px] lg:h-auto h-[250px] ">
            <video
              width="100%"
              height="100%"
              className="!h-full object-fill"
              poster={videoThumbNail1}
              src={VIDEO_PARTS1}
              controls
              muted
            />
            {/* <iframe
              className="object-contain"
              width="100%"
              height="100%"
              src={VIDEO_PARTS}
              title="How an Electric Car Works? Its Parts &amp; Functions [Explained]"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> */}
          </div>
        </div>
        <div className="w-full absolute top-1/2 left-0 -z-10">
          <img src={Shadow5} className="w-full" alt="" />
        </div>
      </section>
      <div style={{ height: '40px' }} />
      {/* <!-- VIDEO-END --> */}
      {/* <!-- VIDEO-START --> */}
      <section className=" relative">
        <div className="container px-4 mx-auto max-w-6xl">
          <div className="w-full rounded sm:rounded-[3rem] overflow-hidden sm:h-[430px] md:h-[500px] lg:h-auto h-[250px] ">
            <video
              width="100%"
              height="100%"
              className="!h-full object-fill"
              poster={videoThumbNail}
              src={VIDEO_PARTS}
              controls
              muted
            />
          </div>
        </div>
        <div className="w-full absolute top-1/2 left-0 -z-10">
          <img src={Shadow5} className="w-full" alt="" />
        </div>
      </section>
      {/* <!-- VIDEO-END --> */}
      {/* <!-- FEATURES-START --> */}
      <section className="sm:py-24 py-14">
        <div className="container mx-auto px-4 max-w-6xl">
          <h2 className="text-themetext text-center sm:text-4xl text-2xl font-semibold">
            Features of PartsXchange
          </h2>
          <div className="grid sm:grid-cols-3 grid-cols-1 gap-4 lg:pt-14 pt-8">
            <div className="lg:px-8 sm:block flex flex-col justify-center items-center text-center">
              <div className="mx-auto">
                <img src={Feature} alt="" className="mx-auto" />
              </div>
              {/* <h3 className="text-themetext text-2xl font-semibold pt-2.5">
                Secure Data
              </h3> */}
              <p className=" opacity-70 md:text-lg pt-1 font-semibold">
                Sell and/or buy parts
              </p>
            </div>
            <div className="lg:px-8 sm:block flex flex-col justify-center items-center">
              <div className="mx-auto">
                <img src={Feature2} alt="" className="mx-auto" />
              </div>
              {/* <h3 className=" text-2xl font-semibold pt-2.5">
                Secure Data
              </h3> */}
              <p className="text-center opacity-70 md:text-lg pt-1 font-semibold">
                We also offer a platform for a directory of services and an
                advertising platform with news feeds
              </p>
            </div>
            <div className="lg:px-8 sm:block text-center flex flex-col justify-center items-center">
              <div className="mx-auto ">
                <img src={Feature3} alt="" className="mx-auto" />
              </div>
              {/* <h3 className=" text-2xl font-semibold pt-2.5">
                Secure Data
              </h3> */}
              <p className=" opacity-70 md:text-lg pt-1 font-semibold">
                We have added a resolution center to resolve any issues
                regarding part sales
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- FEATURES-END --> */}
      {/* <!-- IMAGES-START --> */}
      <section>
        <div className="w-full">
          <img src={Iphones} className="w-full object-contain" alt="" />
        </div>
      </section>
      {/* <!-- IMAGES-END --> */}
      {/* <!-- CONTACTUS-START --> */}
      <section
        className="bg-footer-index relative"
        style={{ marginTop: "6rem" }}
      >
        <div className="container mx-auto px-4 max-w-6xl">
          <div className="grid sm:grid-cols-5 grid-cols-3 bg-[#f9f9f9] rounded-2xl">
            <div className="col-span-3 sm:border-dashed sm:border-r-2 lg:px-14 lg:py-7 p-7 sm:border-[#126D7C]/50">
              <div>
                <h3 className="text-themetext lg:text-3xl text-2xl font-medium md:py-1.5">
                  Contact Us
                </h3>
                <p className="text-themetext/70 md:text-lg pb-2">
                  Our friendly team would love to hear from you!
                </p>
                <Formik
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    email: "",
                    message: "",
                  }}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string().required("First name is required"),
                    last_name: Yup.string().required("Last name is required"),
                    email: Yup.string().required("Email is required"),
                    message: Yup.string().required("Message is required"),
                  })}
                  onSubmit={(values, { resetForm }) => {
                    // toast.success(
                    //   "Your message sent successfully. We will come back to you soon."
                    // );
                    contactUsAPI(values).then(
                      (res) => res?.status === 200 && resetForm()
                    );
                  }}
                  render={({ errors, status, touched }) => (
                    <Form className="md:space-y-6 space-y-3.5">
                      <div className="grid md:grid-cols-2 gap-3 pt-3">
                        <div>
                          <label
                            htmlFor=""
                            className="text-themetext/80 font-medium"
                          >
                            First name
                          </label>
                          <Field
                            name="first_name"
                            type="text"
                            className="bg-input px-5 py-2 md:text-lg focus:outline-none w-full rounded-lg text-themetext"
                            placeholder="First name"
                          />
                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor=""
                            className="text-themetext/80 font-medium"
                          >
                            Last name
                          </label>
                          <Field
                            name="last_name"
                            type="text"
                            className="bg-input px-5 py-2 md:text-lg focus:outline-none w-full rounded-lg text-themetext"
                            placeholder="Last name"
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="text-red-500 text-xs"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor=""
                          className="text-themetext/80 font-medium"
                        >
                          Email
                        </label>
                        <Field
                          name="email"
                          type="email"
                          className="bg-input px-5 py-2 md:text-lg focus:outline-none w-full rounded-lg text-themetext"
                          placeholder="Email address"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor=""
                          className="text-themetext/80 font-medium"
                        >
                          Message
                        </label>
                        <Field
                          as={"textarea"}
                          type="text"
                          name="message"
                          className="bg-input px-5 py-2 md:text-lg focus:outline-none w-full rounded-lg resize-none text-themetext"
                          placeholder="Write message..."
                        ></Field>
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="text-red-500 text-xs"
                        />
                      </div>
                      <button
                        type="submit"
                        className="bg-theme py-2 px-14 flex items-center justify-center text-lg text-white shadow-md font-normal shadow-theme/70 rounded-full"
                      >
                        <span style={{ marginTop: "3px" }}>Send</span>
                      </button>
                    </Form>
                  )}
                />
              </div>
            </div>
            <div className="sm:col-span-2 col-span-3 lg:px-14 lg:py-7 p-7 w-full">
              <div className="flex flex-col justify-between h-full w-full">
                <div>
                  <h3 className="text-themetext lg:text-2xl md:text-xl text-lg font-medium sm:py-1.5 ">
                    Email
                  </h3>
                  <p className="text-themetext/70 md:text-lg break-words pb-2">
                    <a href="mailto:admin@partsxchange.co.uk">
                      admin@partsxchange.co.uk
                    </a>
                  </p>
                </div>
                <div>
                  <h3 className="text-themetext lg:text-2xl md:text-xl text-lg font-medium sm:py-1.5 sm:mt-0 mt-4">
                    Phone
                  </h3>
                  <p className="text-themetext/70 md:text-lg pb-2">
                    <a href="tel:03303903382">0330 390 3382</a>
                  </p>
                </div>
                <div className="w-full">
                  <h3 className="text-themetext lg:text-2xl md:text-xl text-lg font-medium sm:py-1.5 sm:mt-0 mt-4">
                    Address
                  </h3>
                  <p className="text-themetext/70 md:text-lg pb-2 w-full">
                    Hare Street, Buntingford, SG9 0EA
                  </p>
                </div>
                <div className="flex sm:mt-0 mt-4">
                  <a href={"https://www.instagram.com/"} alt="" target="_blank">
                    <img src={Instagram} alt="" />
                  </a>
                  <a href={"https://twitter.com/"} alt="" target="_blank">
                    <img src={Twitter} alt="" className="ml-4" />
                  </a>
                  <a href={"https://www.facebook.com/"} alt="" target="_blank">
                    <img src={Facebook} alt="" className="ml-4" />
                  </a>
                  <a href={"https://in.linkedin.com/"} alt="" target="_blank">
                    <img src={Linkedin} alt="" className="ml-4" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-center py-12">
            <div className="xl:w-[320px] w-[250px]">
              <img src={Logo} className="w-full" alt="" />
            </div>
          </div>
          <div
            className="flex flex-wrap md:flex-nowrap items-center justify-center px-4"
            style={{ paddingBottom: "3rem" }}
          >
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/terms-condition">Terms & Conditions</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
            <a href="https://partsxchange.co.uk/DataProtectionPolicy.pdf">Data Protection Policy</a>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/help_and_support">Help And Support</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/parts_grading">Parts Grading</Link>
            </p>
            <p className="text-white text-lg lg:px-4 px-2 font-normal">|</p>
            <p className="text-white hover:underline sm:text-lg font-normal cursor-pointer">
              <Link to="/partners">Partners</Link>
            </p>
          </div>
        </div>
      </section>
      {/* <!-- CONTACTUS-END --> */}
    </main>
  );
};

export default Home;
